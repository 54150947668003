<template>
  <div class="view">
    <transition name="fade">
      <ul class="list" v-if="loading > 0">
        <template v-for="(day, i) in list">
          <li v-if="day.items.length" :key="i">
            <div class="list-header" v-if="day">
              <span class="list-date">{{ day.day + ' ' + day.number + ' ' + day.month }}</span>
              <span class="list-title" v-if="day.title">{{ day.title }}</span>
            </div>
            <ul class="list-items">
              <li v-for="(item, j) in day.items" :key="j">
                <div class="item" :class="{'-live': item.live}" v-if="item.events">
                  <div class="item-header">
                    <i class="item-icon" v-if="item.icon" :class="'icon-' + item.icon"></i>
                    <span class="item-title" v-if="item.title">{{ item.title }}</span>
                    <span class="item-type" v-if="item.type">{{ item.type }}</span>
                    <template v-if="item.events.length === 1">
                      <span v-if="item.events[0].links.join && item.events[0].joinIn && item.events[0].joinIn !== 'live' && item.events[0].joinIn !== 'coming soon' && item.events[0].joinIn !== 'passed'" class="event-join"><i class="icon-alert" v-if="item.events[0].joinIn"></i> {{ item.events[0].joinIn }}</span>
                      <a class="event-link -full" v-if="item.events[0].links.join && item.events[0].joinIn && item.events[0].joinIn !== 'coming soon' && item.events[0].joinIn !== 'passed'" :href="item.events[0].links.join"><i class="icon-news_date"></i> {{ $t('Join now') }}</a>
                      <a class="event-link" v-if="item.events[0].links.replay && item.events[0].joinIn && item.events[0].joinIn === 'passed'" :href="item.events[0].links.replay" @click="tcEvent(item, 'list_replayClick')" target="_blank">{{ $t('Replay') }}</a>
                      <a class="event-link" v-if="item.events[0].links.calendar && item.events[0].joinIn && item.events[0].joinIn === 'coming soon'" :href="item.events[0].links.calendar"><i class="icon-calendar"></i> {{ $t('Add to calendar') }}</a>
                      <a class="event-link" v-if="item.events[0].links.share" :href="shareLink(item.events[0].links.share, item.title)" @click="tcEvent(item, 'list_shareClick')" target="_blank"><i class="icon-mail"></i> {{ $t('Share') }}</a>
                    </template>
                  </div>
                  <ul class="list-events">
                    <li v-for="(event, k) in item.events.filter((i) => filterEvents.event ? i[filterEvents.type + '_id'] === filterEvents.event.id : i['global'])" class="event" :key="k">
                      <span v-if="event.date"><i class="icon-watch"></i> {{ event.date }}</span>
                      <span v-if="event.authors"><i class="icon-speak"></i> {{ event.authors }}</span>
                      <span v-if="event.lang"><i class="icon-assistance"></i> {{ event.lang }}</span>
                      <template v-if="item.events.length > 1">
                        <span v-if="event.links.join && event.joinIn && event.joinIn !== 'live' && event.joinIn !== 'passed' && event.joinIn !== 'coming soon'" class="event-join"><i class="icon-alert" v-if="event.joinIn"></i> {{ event.joinIn }}</span>
                        <a class="event-link -full" v-if="event.links.join && event.joinIn && event.joinIn !== 'passed' && event.joinIn !== 'coming soon'" :href="event.links.join"><i class="icon-news_date"></i> {{ $t('Join now') }}</a>
                        <a class="event-link" v-if="event.links.replay && event.joinIn && event.joinIn === 'passed'" :href="event.links.replay" @click="tcEvent(item, 'list_replayClick')" target="_blank">{{ $t('Replay') }}</a>
                        <a class="event-link" v-if="event.links.calendar && event.joinIn && event.joinIn === 'coming soon'" :href="event.links.calendar"><i class="icon-calendar"></i> {{ $t('Add to calendar') }}</a>
                        <a class="event-link" v-if="event.links.share" :href="shareLink(item.events[0].links.share, item.title)" @click="tcEvent(item, 'list_shareClick')" target="_blank"><i class="icon-mail"></i> {{ $t('Share') }}</a>
                      </template>
                    </li>
                  </ul>
                  <div class="item-description" v-if="item.description">
                    <div class="item-visual" v-if="item.description.visual">
                      <img :src="item.description.visual.src" :alt="item.description.visual.alt">
                    </div>
                    <p v-if="item.description.text" v-html="item.description.text"></p>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'List',
  props: ['calendarJson', 'eventsFiltered', 'filterEvents', 'events'],
  data() {
    return {
      list: [],
      loading: 0,
      interval: null
    }
  },
  watch: {
    eventsFiltered () {
      this.mountList()
    },
    calendarJson () {
      this.mountList()
    }
  },
  mounted () {
    if (this.calendarJson) {
      this.mountList()
    }
  },
  methods: {
    mountList() {
      this.list = []
      this.loading = 0
      this.list = this.calendarJson.header

      if (this.events.length === this.eventsFiltered.length && this.eventsFiltered.filter((e) => e.global).length && this.events.length !== this.eventsFiltered.filter((e) => e.global).length) {
        this.$emit("filterEvents", { event: null, type: 'global' }) ;
      }

      for(let i = 0; i <= this.list.length - 1; i++) {
        let events = this.eventsFiltered.filter((e) => parseInt(e.start_day) === i)
        this.list[i].items = []

        for(let j = 0; j <= events.length - 1; j++) {
          let dayExist = this.list[i].items.findIndex((id) => id.id === events[j].infos.id)
          if (dayExist !== -1) {
            this.list[i].items[dayExist]['events'].push(events[j])
          } else {
            events[j].infos.events = []
            events[j].infos.events.push(JSON.parse(JSON.stringify(events[j])))
            this.list[i].items.push(events[j].infos)
          }
          this.$forceUpdate()
          this.loading = 1
        }
      }

      clearInterval(this.interval)
      this.interval = setInterval(() => {
        for(let i = 0; i <= this.list.length - 1; i++) {
          for(let j = 0; j <= this.list[i].items.length - 1; j++) {
            for(let k = 0; k <= this.list[i].items[j].events.filter((i) => this.filterEvents.event ? i[this.filterEvents.type + '_id'] === this.filterEvents.event.id : i['global']).length - 1; k++) {
              this.loading += k
              this.joinIn(i, j, k)
              this.$forceUpdate()
            }
          }
        }
      }, 1000)
    },
    shareLink(str,title){
      return str.replace('$title', title).replace('$link', document.location.href)
    },
    joinIn(day, item, event) {
      let date = new Date;
      let eventDate = new Date(this.list[day].items[item].events[event].month + ' ' + this.list[day].items[item].events[event].day + ', ' + this.list[day].items[item].events[event].year + ' ' + this.list[day].items[item].events[event].hour + ':' + this.list[day].items[item].events[event].minutes + ':00')
      let time = Math.floor((eventDate - date) / 1000 / 60)
      if (time > 0 && time <= window.progData['join-now-start']) {
        this.list[day].items[item].live = true
        this.list[day].items[item].events[event].joinIn = this.$t('In x minutes', { 'time': time })
      } else {
        if (time <= 0) {
          if (time >= (-1 * this.list[day].items[item].events[event].duration)) {
            this.list[day].items[item].live = true
            this.list[day].items[item].events[event].joinIn = 'live'
          } else {
            this.list[day].items[item].live = false
            this.list[day].items[item].events[event].joinIn = 'passed'
          }
        } else {
          this.list[day].items[item].events[event].joinIn = 'coming soon'
        }
      }
    },
    tcEvent(item, eventId) {
      window.tc_events_global(this, eventId, {
        evt_category: 'list',
        evt_button_action: eventId,
        evt_button_label: item.title
      })

      console.log('TAGCO => tc_events_global: ', eventId, {
        evt_category: 'list',
        evt_button_action: eventId,
        evt_button_label: item.title
      })
    }
  },
  destroyed: function () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.view {
  padding: 0 4rem;
  @include bp-down(lg) {
    padding: 0 2rem;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    margin-bottom: 4rem;
  }

  &-items {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      margin-bottom: 2rem;
    }
  }

  &-events {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-header {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;

    @include bp-down(lg) {
      flex-wrap: wrap;
    }
  }

  &-date {
    color: $primary;
    font-weight: normal;
    text-transform: uppercase;

    @include bp-down(lg) {
      width: 100%;
    }
  }

  &-title {
    @include bp-down(lg) {
      width: 100%;
      margin-top: .4rem;
    }
  }

  &-type {
    color: $primary;
    font-size: em(22);
    line-height: em(30, 22);
  }
}

.item {
  position: relative;
  background: rgba(255,255,255,0.8);
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  padding: 2.4rem 1.4rem 2.4rem 7rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.05);

  @include bp-down(lg) {
    padding: 2rem 1rem;
  }

  &.-live {
    border-left-color: $secondary;
  }

  &-header {
    position: relative;
    display: flex;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: flex-start;

    @include bp-down(lg) {
      flex-wrap: wrap;
    }

  }

  &-icon {
    position: absolute;
    left: -1.6rem;
    top: 50%;
    font-size: 40px;
    color: $primary;
    transform: translate(-100%, -50%);

    @include bp-down(lg) {
      left: 0;
      top: 0;
      transform: none;
      position: static;
    }
  }

  &-type {
    display: inline-block;
    font-weight: normal;
    color: $primary;
    text-transform: uppercase;
    font-weight: 300;
    @include bp-down(lg) {
      order: 1;
      width: 100%;
      margin-top: 2rem;
    }
  }

  &-title {
    display: inline-block;
    color: $primary;
    margin-right: 2rem;
    font-weight: 400;
    @include bp-down(lg) {
      order: 2;
      width: 100%;
    }
  }

  &-description {
    max-width: 80%;
    font-size: em(14);
    line-height: em(16, 14);
    overflow-x: auto;

    @include bp-down(lg) {
      max-width: 100%;
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }

    p {
      margin-bottom: 0;
    }
  }

  &-visual {
    float: left;
    max-width: 12rem;
    margin-right: 1rem;
  }
}

.event {
  display: flex;
  margin-bottom: 1.8rem;
  align-items: center;
  justify-content: flex-start;

  @include bp-down(lg) {
    width: 100%;
    flex-wrap: wrap;
  }

  span, &-join {
    margin-right: 2rem;
    font-size: em(14);
    line-height: em(14, 12);

    @include bp-down(lg) {
      display: block;
      width: 100%;
      margin-right: 0;
      padding: 1rem 0;

      &:not(:first-child) {
        border-top: 1px dashed $primary;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    i {
      display: inline-block;
      font-size: em(18, 12);
      color: $primary;
      vertical-align: middle;
      position: relative;
      top: -.1rem;
      float: left;
      margin-right: 0.8rem;

      @include bp-down(lg) {
        float: none;
      }
    }
  }

  @include bp-down(lg) {
    .event-link {
      flex-grow: 1;
      margin-left: 0;
    }
  }

  &-link {
    margin-left: auto;

    & + .event-link {
      margin-left: 1rem;
    }
  }

  &-join {
    margin-left: auto;
    @include bp-down(lg) {
      margin-left: 0;
      margin-bottom: 2rem;
    }

    & + .event-link {
      margin-left: 0;
    }
  }

  & + .event {
    padding-top: 1.8rem;
    border-top: 1px solid $border;

    @include bp-down(lg) {
      padding-top: 1rem;
    }
  }
}

</style>
